import App, { AppContext } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { NextComponentType } from 'next';
import { AbTestingProvider, DeviceInfoProvider, ThemeProvider } from '@sravni/react-utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CommonHead, ICommonHeadProps } from '@sravni/next-common-head';
import { parseWLUrl } from '@sravni/utils/lib/analytics';
import { ThemeName } from '@sravni/design-system-theme';
import { GTM_ID } from '../constants/analytics';
import { PAGE } from '../constants';
import { SeoHead } from '../components/global/head';

import { Header } from '../components/global/header';
import { ServersideProps } from '../helpers/serverside.props';
import { Footer } from '../components/global/footer';
import { CaptchaClient } from '../helpers/captcha';
import { queue } from '../helpers/queue';
import { createStore, IGlobalState } from '../store';
import { setStore } from '../store/client';
import { CommonSideEffects } from '../components/common.side.effects';
import { isDevelopment } from '../helpers';
import { MainSkeletonProvider } from '../components/landing.common/main.skeleton';

import '@sravni/design-system-theme/lib/globalStyles.css';
import '../styles/global.scss';
import { AllSeoBlocks } from '../components/global/seo';
import { ErrorBoundaryFallbackProvider } from '../components/error.boundary.fallback';
import { ClientTraceContext } from '../helpers/clientTraceContext';

interface Props extends AppContext {
    Component: NextComponentType;
    pageProps: ServersideProps & {
        asWhiteLabel?: boolean;
    };
}

export default class CreditSelection extends App<Props> {
    /** Глобальный Redux Store */
    store: Store<IGlobalState>;
    /** Для корректной работы React-Query */
    queryClient = new QueryClient();

    constructor(props: Props) {
        super(props);
        this.store = createStore(props.pageProps.initialState);
        setStore(this.store);
    }

    componentDidMount() {
        const { user } = this.store.getState();
        const account = user.loggedIn ? user.account : undefined;
        ClientTraceContext.setSessionId();
        ClientTraceContext.setUserId(account?.sub);

        queue.push(() => {
            CaptchaClient.load();
        });
    }

    get deviceInfo() {
        if (this.props.pageProps.deviceInfo) {
            return this.props.pageProps.deviceInfo;
        }
        return { phone: false, tablet: false };
    }

    render() {
        const { Component, pageProps } = this.props as Props;
        const { initialState, abTestingInfo } = pageProps;
        const initialTheme = pageProps.theme || ThemeName.lager;

        const userId = initialState
            ? initialState.user.loggedIn
                ? initialState.user.account.sub
                : undefined
            : undefined;

        const isWhitelabel =
            [PAGE.AVITO, PAGE.WHITELABEL, PAGE.CARDS_WHITELABEL, PAGE.AGENTS].includes(pageProps.pageName) ||
            pageProps.asWhiteLabel;
        // TODO: Переделать убрав отрицание
        const hasFooter = pageProps?.pageName !== PAGE.DEALS;

        const analyticsOptions: ICommonHeadProps['analyticsOptions'] = {
            userId,
            enabled: !isDevelopment,
            ab: 'AB_CREDITSELECTION',
            googleTmId: pageProps.gtmId || GTM_ID.common,
            wl: isWhitelabel ? parseWLUrl(pageProps.originalUrl) : undefined,
            isWebview: pageProps.isWebView,
            fullURL: encodeURI(pageProps.originalUrl),
            webvisor: !isDevelopment,
            theme: initialTheme,
            yandexMetricsOptions: {},
        };

        const preConnectedDomains = [
            'https://vk.com',
            'https://cdnjs.cloudflare.com',
            'https://static.server.retentioneering.com',
        ];

        const seo = initialState?.metadata.metadata;

        return (
            <ErrorBoundaryFallbackProvider>
                <AbTestingProvider initialValue={abTestingInfo}>
                    <Provider store={this.store}>
                        <ThemeProvider initialTheme={isWhitelabel ? 'lager' : initialTheme}>
                            <DeviceInfoProvider initialInfo={this.deviceInfo}>
                                <QueryClientProvider client={this.queryClient}>
                                    <CommonHead
                                        preconnectedDomains={preConnectedDomains}
                                        analyticsOptions={analyticsOptions}
                                    />
                                    {seo && <SeoHead seo={seo} realPath={pageProps.realPath} />}

                                    <CommonSideEffects />

                                    {isWhitelabel && (
                                        <MainSkeletonProvider>
                                            <Component {...(pageProps as any)} />
                                        </MainSkeletonProvider>
                                    )}

                                    {!isWhitelabel && (
                                        <div className="main-wrapper">
                                            <Header />
                                            <MainSkeletonProvider>
                                                <Component {...(pageProps as any)} />
                                            </MainSkeletonProvider>
                                            <AllSeoBlocks />
                                            {hasFooter && (
                                                <div className="footer-wrapper">
                                                    <Footer />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </QueryClientProvider>
                            </DeviceInfoProvider>
                        </ThemeProvider>
                    </Provider>
                </AbTestingProvider>
            </ErrorBoundaryFallbackProvider>
        );
    }
}
