import React from 'react';
import { Footer as FooterDefault } from '@sravni/react-footer';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../store';
import styles from './footer.module.scss';

export const Footer: React.FC = () => {
    const settings = useSelector((state: IGlobalState) => state.siteSettings.footer);

    return (
        <div className={styles['footer-container']}>
            <FooterDefault menuLinks={settings.menu} socialLinks={settings.socialLinks} support={settings.support} />
        </div>
    );
};
